import {request} from '../data';

export class Api {
  constructor (httpRequest = request) {
    this._httpRequest = httpRequest;
  }

  _request ({method, path, query, headers = {}, body = null}) {
    const requestHeaders = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json;charset=UTF-8',
      'X-REQUEST-CLIENT-TYPE': 'web',
      'X-REQUEST-CLIENT-VERSION': '1.0',
      'X-REQUEST-ID': Date.now(),
      ...headers
    };

    return this._httpRequest({
      method,
      url: path,
      query,
      headers: requestHeaders,
      body
    });
  }

  _get (requestParams) {
    return this._request({
      ...requestParams,
      method: 'GET',
      body: null
    });
  }

  _post (requestParams) {
    return this._request({
      ...requestParams,
      method: 'POST'
    });
  }

  getTimeSeries (measure, period, component = '*', sector, mapLayerClassifier) {
    let path = `https://sit.skybonds.io/api/v1/profits/${measure}/${period}`;
    if (component) {
      path = `${path}/profit:${component}`
    }
    if (sector) {
      path = `${path}/${sector}`
    }
    if (mapLayerClassifier) {
      path = `${path}/${mapLayerClassifier}`
    }

    return this._get({
      path
    });
  }

  getValuesByClassifier (classifier) {
    return this._get({
      path: `https://sit.skybonds.io/api/v1/profits/classifiers/${classifier}`
    });
  }

  getCountryInfo (countryId, period) {
    return this._get({
      path: `https://sit.skybonds.io/api/v1/countries/${countryId}/summary/${period}`
    });
  }
}
