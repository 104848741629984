import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Media from 'react-media';

import { ReactComponent as MainLogo } from '../../../images/mainLogo.svg';
import { ReactComponent as MainLogoMobile } from '../../../images/mainLogoMobile.svg';
import { ReactComponent as AtlasActiveIcon} from '../../../images/atlasActiveIcon.svg';
import { ReactComponent as ComponentsInactiveIcon} from '../../../images/componentsInactiveIcon.svg';
import { ReactComponent as ComponentsActiveIcon} from '../../../images/componentsActiveIcon.svg';
import { ReactComponent as AtlasInactiveIcon} from '../../../images/atlasInactiveIcon.svg';

import styles from './styles.sass';

export const NavigationPanel = () => {
  return (
    <div className="navigationPanel">
      <div className="mainLogo">
        <Media queries={{
          small: "(max-width: 820px)",
          large: "(min-width: 1200px)",
        }}>
          {matches => (
            <>
              {matches.small && <MainLogoMobile />}
              {matches.large && <MainLogo />}
            </>
          )}
        </Media>
      </div>

      <div className="navigationTabs">
        <NavLink
          className="navigationTab"
          activeClassName="active"
          data-id="mainMenu_profitAtlas"
          to="/"
          children={({ isActive }) => (
            <>
              {isActive ? <AtlasActiveIcon /> : <AtlasInactiveIcon />}
              <div className={classNames('navigationTab_title', { inactiveTitle: !isActive })}>
                Profit atlas
              </div>
            </>
          )}
        />
        <NavLink
          className="navigationTab"
          activeClassName="active"
          data-id="mainMenu_profitComponents"
          to="/components"
          children={({ isActive }) => (
            <>
              {isActive ? <ComponentsActiveIcon /> : <ComponentsInactiveIcon />}
              <div className={classNames('navigationTab_title', { inactiveTitle: !isActive })}>
                Profit components
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
}