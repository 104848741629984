import LoadingSpinner from '../LoadingSpinner';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import './styles.sass';

const MessageCover = ({
  additionalClassName,
  caption,
  dataId,
  isError,
  isPending,
  isActive,
  onClick,
  children
}) => {
  if (!isActive) {
    return null;
  }
  return (
    <div
      className={classNames(
        "messageCover",
        {["__loading"]: isPending},
        {["__clickable"]: onClick},
        {["__error"]: isError},
        {[additionalClassName]: additionalClassName}
      )}
      data-id={dataId}
      onClick={onClick
        ? onClick
        : () => null}
    >
      <div className={"messageCover_container"}>
        <div>
          <div className={classNames(
            "messageCover_caption",
            {["__loading"]: isPending},
            {["__text"]: !isPending}
          )}>
            {caption || children}
          </div>
          {isPending && (
            <div
              className={"messageCover_dots"}
              data-id="messageCover_pending"
            >
              <LoadingSpinner />
            </div>
          )}
          {isError && <div data-id="messageCover_error" />}
        </div>
      </div>
    </div>
  );
};

MessageCover.propTypes = {
  additionalClassName: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.node,
  dataId: PropTypes.string,
  isActive: PropTypes.bool,
  isError: PropTypes.bool,
  isPending: PropTypes.bool,
  onClick: PropTypes.func
};

MessageCover.defaultProps = {
  additionalClassName: '',
  caption: null,
  children: null,
  dataId: null,
  isActive: true,
  isError: false,
  isPending: false,
  onClick: null
};

export default MessageCover;
