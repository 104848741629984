import {getCountryName} from './../../modules/Country'

const handleTimeSeriesResult = (response) => {
  const dataByCountyMap = {};
  response.data.map((item) => {
    const countryIndex = response.columns.findIndex((columnName) => columnName === 'country');
    const profitsIndex = response.columns.findIndex((columnName) => columnName === 'profit');
    const interpolationLevelIndex = response.columns.findIndex((columnName) => columnName === 'interpolation_level');
    const mapLayerId = item[countryIndex].replace('country:', '').replace('world:', '');
    const profitId = item[profitsIndex].replace('profit:', '');
    if (dataByCountyMap[mapLayerId]) {
      dataByCountyMap[mapLayerId][profitId] = item.slice(5);
    } else {
      dataByCountyMap[mapLayerId] = {
        periods: response.columns.slice(5).map((serverYear) => serverYear.replace('Y:', '')),
        interpolationLevel: item.slice(interpolationLevelIndex, 5),
        [profitId]: item.slice(5),
      }
    }
  })

  return dataByCountyMap;
};

const handleExportTimeSeriesResult = (rawData) => {
  const normalizeResponse = (response) =>
    ({
      columns: response.columns.map((item, index) => {
        if (index < 5) {
          return (item.charAt(0).toUpperCase() + item.slice(1)).replace('_', ' ');
        }
        return item.replace('Y:', '');
      }),
      data: response.data.map((values) => {
        return values.map((value, index) => {
          const countryIndex = response.columns.findIndex((columnName) => columnName === 'country');
          const profitsIndex = response.columns.findIndex((columnName) => columnName === 'profit');
          if (index === countryIndex) {
            return getCountryName(value.replace('country:', ''));
          }
          if (index === profitsIndex) {
            return value.replace('profit:', '') + ', $bln'
          }
          return value
        })
      }),
    });

  const filterData = (normalizedData) => {
    const profitsIndex = normalizedData.columns.findIndex((columnName) => columnName === 'Profit');
    const rowWithProfitIndex = normalizedData.data.findIndex((row) => row[profitsIndex].includes('Profit'));
    const startIndexWithData = normalizedData.data[rowWithProfitIndex].findIndex((value, index) => index > 4 && value);
    return ({
      columns: normalizedData.columns.slice(0, 5).concat(normalizedData.columns.slice(startIndexWithData)),
      data: normalizedData.data.map((row) => row.slice(0, 5).concat(row.slice(startIndexWithData)))
    })
  }

  const normalizedData = normalizeResponse(rawData);
  return filterData(normalizedData);
};

const handleValuesByClassifier = (layerMapData) => {
  let map = {};
  const arrayOfPaths = layerMapData.values.map((value) => value.split(':'));
  let depth = 0;
  arrayOfPaths.forEach((paths) => {
    if (paths.length > depth)
    depth = paths.length
  });
  arrayOfPaths.forEach((paths) => {
    if (paths.length === 1) {
      return;
    }
    if (typeof map[paths[0]] === 'undefined') {
      map[paths[0]] = depth === 2
        ? []
        : {}
    }
    if (typeof map[paths[0]][paths[1]] === 'undefined') {
      if (Array.isArray(map[paths[0]])) {
        map[paths[0]].push(paths[1])
        return
      } else {
        map[paths[0]][paths[1]] = []
      }
    }
    if (paths[2]) {
      map[paths[0]][paths[1]].push(paths[2])
    }
  })
  return map;
}

export class Provider {
  constructor (ApiClient) {
    this._client = ApiClient;
  }

  async getTimeSeries (
      measure,
      period,
      component = '*',
      sector,
      mapLayerClassifier
  ) {
    const result = await this._client.getTimeSeries(measure, period, component, sector, mapLayerClassifier);
    return handleTimeSeriesResult(result);
  }

  async getExportTimeSeries (
      measure,
      period,
      component = '*',
      sector,
      mapLayerClassifier
  ) {
    const result = await this._client.getTimeSeries(measure, period, component, sector, mapLayerClassifier);
    return handleExportTimeSeriesResult(result);
  }

  async getValuesByClassifier (classifier) {
    const result = await this._client.getValuesByClassifier(classifier);
    return handleValuesByClassifier(result);
  }

  async getCountryInfo(countryId, period) {
    const result = await this._client.getCountryInfo(countryId, period);
    const countryInfoData = result[0];
    return [{
      indicator: 'Population',
      value: countryInfoData.population,
      unit: 'm'
    }, {
      indicator: 'CPI',
      value: countryInfoData.cpi,
      unit: '%'
    }, {
      indicator: 'Current account',
      value: countryInfoData.currentAccount,
      unit: 'b'
    }, {
      indicator: 'External government debt',
      value: countryInfoData.externalGovernmentDebt,
      unit: 'b'
    }, {
      indicator: 'GDP',
      value: countryInfoData.gdp,
      unit: 'b'
    }, {
      indicator: 'GDP growth',
      value: countryInfoData.gdpGrowth,
      unit: '%'
    }, {
      indicator: 'GDP per capita',
      value: countryInfoData.gdpPerCapita,
      unit: 'b'
    }, {
      indicator: 'International Reserves',
      value: countryInfoData.internationalReserves,
      unit: ''
    }, {
      indicator: 'Main Index P/E',
      value: countryInfoData.mainIndexPe,
    }, {
      indicator: 'Market capitalization',
      value: countryInfoData.marketCap,
      unit: 'b'
    }, {
      indicator: 'Share of Public Profits',
      value: countryInfoData.shareOfPublicProfits,
      unit: 'b'
    }, {
      indicator: 'Share of Public Profits х Corporate Profits IFRS',
      value: countryInfoData.shareOfPublicProfitsCorporateProfitsIfrs,
      unit: 'b'
    }, {
      indicator: 'Total Exernal Debt',
      value: countryInfoData.totalExernalDebt,
      unit: 'b'
    }]
  }
}
