import lodash from 'lodash';

export const getResponseErrorMessage = async (
  method,
  url,
  headers,
  body,
  response
) => { // eslint-disable-line max-params
  const secureHeaders = lodash.cloneDeep(headers);
  if (secureHeaders['X-Access-Token']) {
    secureHeaders['X-Access-Token'] = '*';
  }

  const secureBody = body
    ? lodash.cloneDeep(body)
    : null;
  if (secureBody && secureBody.pwd) {
    secureBody.pwd = '*';
  }

  const responseData = await response.json();
  return `${method} ${url} ${response.status} ${response.statusText}\r\nMessage: ${responseData?.message}\r\nBody: ${secureBody}`;
};
