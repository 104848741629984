import React, {lazy} from 'react';
import Application from './modules/Application';
import ReactDOM from 'react-dom';
import './styles.sass';
import reportWebVitals from './reportWebVitals';
import './ui/styles/_reset.sass';
import './ui/styles/_fonts.sass';
import {BrowserRouter, Route, Routes} from "react-router-dom";

import { NavigationPanel } from "./ui/components/common/NavigationPanel";

import MessageCover from "./ui/components/common/MessageCover";

const PageProfitAtlas = lazy(() => import(/* webpackChunkName: "bond" */ './ui/components/pages/ProfitAtlas/index'));
const PageProfitComponents = lazy(() => import(/* webpackChunkName: "bond" */ './ui/components/pages/ProfitComponents/index'));
Application.init();

ReactDOM.render(
  <React.StrictMode>
      <div className="worldProfits">
          <React.Suspense fallback={
              <MessageCover isPending />
          }>
            <BrowserRouter>
                <NavigationPanel />
                <Routes>
                    <Route path="/" element={<PageProfitAtlas />} />
                    <Route path="/components" element={<PageProfitComponents />} />
                </Routes>
            </BrowserRouter>
          </React.Suspense>
      </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
