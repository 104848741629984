class ProviderManager {
  init ({
    provider
  }) {
    this.provider = provider;
  }

  getProvider () {
    return this.provider;
  }
}

export default ProviderManager;
