import * as worldCountries from "./data/world_countries.json";

export const getCountryName = (countryId) => {
    const countryInfo = worldCountries.default.features.find((item) => item.id === countryId);
    return countryInfo
        ? countryInfo.properties.name
        : countryId;
}

export const MAP_LAYERS_IDS = {
    WORLD: 'world',
    REGION: 'region',
    COUNTRY: 'country',
};

const getCoordinates = (countriesIds = null) => {
    const getArrayDepth = value => Array.isArray(value) ?
        1 + Math.max(0, ...value.map(getArrayDepth)) :
        0;
    const data = countriesIds === null
        ? worldCountries.default.features
        : countriesIds.map((countryId) => worldCountries.default.features.find(({id}) => id === countryId))
            .filter((value) => typeof value !== 'undefined')
    return data.reduce((coordinate, {geometry}) => {
        if (getArrayDepth(geometry.coordinates) === 4) {
            coordinate.push(...geometry.coordinates)
        } else {
            coordinate.push(geometry.coordinates)
        }
        return coordinate
    }, [])
}

export const getTopojson = (mapLayer, countriesClassifiers) => {
    if (mapLayer === MAP_LAYERS_IDS.WORLD) {
        return {
            features: [{
                "id": 'world',
                "type":"Feature",
                "properties":{"name":"World"},
                "geometry":{"type":"MultiPolygon","coordinates": getCoordinates()}
            }]
        }
    }
    if (mapLayer === MAP_LAYERS_IDS.REGION) {
        const countriesWithData = Object.keys(countriesClassifiers.world)
            .map((regionId) => countriesClassifiers.world[regionId])
            .reduce((array, regionCountries) => {
                array.push(...regionCountries);
                return array;
        }, []);
        const countriesOnPlot = worldCountries.default.features.map(({id}) => id);
        const countriesWithoutData = countriesOnPlot.filter((countryId) => !countriesWithData.includes(countryId));

        const regionsWithData = Object.keys(countriesClassifiers.world).map((regionId) => {
            return {
                "id": regionId,
                "type":"Feature",
                "properties":{"name": regionId},
                "geometry":{"type":"MultiPolygon","coordinates": getCoordinates(countriesClassifiers.world[regionId])}
            }
        });

        if (countriesWithoutData.length > 0) {
            return ({
                features: [
                    ...regionsWithData,
                    {
                        "id": null,
                        "type":"Feature",
                        "properties":{"name": 'No data'},
                        "geometry":{"type":"MultiPolygon","coordinates": getCoordinates(countriesWithoutData)}
                    }
                ]
            });
        }

        return ({features: regionsWithData});
    }
    if (mapLayer === MAP_LAYERS_IDS.COUNTRY) {
        return worldCountries.default;
    }
    return worldCountries;
};