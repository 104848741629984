import React from 'react';
import './styles.sass';

const LoadingSpinner = () => (
  <div
    className={"messageCover_spinner"}
    data-id="loadingPending"
  >
    <div className={"messageCover_dot"} />
    <div className={"messageCover_dot"} />
    <div className={"messageCover_dot"} />
  </div>
);

export default LoadingSpinner;
