import {getResponseErrorMessage} from './getResponseErrorMessage';
import {getUrlWithQuery} from './getUrlWithQuery';

const STATUS_UNAUTHORIZED = 401;
const STATUS_FORBIDDEN = 403;
export const STATUS_NOT_FOUND = 404;
export const STATUS_BAD_REQUEST = 400;

export class RequestError extends Error {
  constructor (message, url, statusCode) {
    super(message);
    this.url = url;
    this.statusCode = statusCode;
  }
}

export const request = async ({
  method,
  url,
  query = {},
  headers = {},
  body = null,
  type = 'json'
}) => {
  const urlWithQuery = getUrlWithQuery(url, query);

  const requestBody = body === null || (typeof FormData !== 'undefined' && body instanceof FormData)
    ? body
    : JSON.stringify(body);

  const response = await fetch(urlWithQuery, {
    method,
    headers,
    body: requestBody
  });

  if (response.ok) {
    switch (type) {
      case 'json': return response.json();
      case 'blob': return response.blob();
      default: throw new Error(`Invalid request type: ${type}`);
    }
  }

  const responseErrorMessage = await getResponseErrorMessage(
    method,
    url,
    headers,
    body,
    response
  );
  throw new RequestError(responseErrorMessage, url, response.status);
};
